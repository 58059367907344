import { ZfTextButton } from "@ccx/zafire-react";
import React, { forwardRef } from "react";

interface ChildComponentProps {
  content: { title: string; body: string; confirm: boolean };
  onClose: () => void;
  onConfirm: () => void;
}

const ModalConfirmation = forwardRef<any, ChildComponentProps>(
  (
    { content, onClose, onConfirm }: ChildComponentProps,
    ref: React.ForwardedRef<any>
  ): JSX.Element => {
    return (
      <dialog
        ref={ref}
        id="default-modal"
        tabIndex={-1}
        aria-hidden="true"
        className="overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 justify-center items-center md:inset-0"
      >
        <div className="relative w-full max-w-2xl max-h-full">
          <div className="relative bg-white rounded-lg shadow dialogContainer">
            <div className="flex items-center justify-between p-4 md:p-5 border-b rounded-t dark:border-gray-200">
              <p className="font-thin">{content.title}</p>
            </div>

            <div className="p-4 md:p-5 space-y-4 border-b dark:border-gray-200">
              <p className="text-base leading-relaxed font-thin">
                {content.body}
              </p>
            </div>

            <div className="flex flex-row-reverse p-3 w-full space-y-4">
              <div className="">
                {content.confirm && (
                  <>
                    <ZfTextButton onClick={onClose} hierarchy="tertiary">
                      Cancel
                    </ZfTextButton>
                    <ZfTextButton
                      onClick={onConfirm}
                      data-testid="acc-cat-save-btn"
                    >
                      Confirm
                    </ZfTextButton>
                  </>
                )}
                {!content.confirm && (
                  <ZfTextButton onClick={onClose} hierarchy="tertiary">
                    Close
                  </ZfTextButton>
                )}
              </div>
            </div>
          </div>
        </div>
      </dialog>
    );
  }
);

export default ModalConfirmation;
