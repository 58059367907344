import { faker } from "@faker-js/faker";
import {
  EntityModelAccountBalanceCobDto,
  EntityModelAccountBalanceDto,
  EntityModelAccountBalanceLimitBreachDto,
  EntityModelAccountBalanceMissingCobDto,
  EntityModelNostroCategoryDto,
  EntityModelNostroOverviewDto,
  EntityModelNostroVsAccountBalanceDto,
  EntityModelOtherNostroViewDto,
  EntityModelRelationshipManagerDto,
  NostroDetailDto,
  PagedModelEntityModelComponentPropertiesDto,
} from "../openapi";

export type ComponentProperty = {
  componentId: string;
  label: string;
  column?: string;
  order?: number;
  openDialog?: boolean;
};

const viewAccProps: ComponentProperty = {
  componentId: "acc-view",
  label: "View Nostro",
  column: "Main",
  order: 2,
};

const otherViewsProps: ComponentProperty = {
  componentId: "other-views",
  label: "Other Views",
  column: "Main",
  order: 3,
};

const templatesProps: ComponentProperty = {
  componentId: "templates",
  label: "Templates",
  column: "Balance Recon",
  order: 11,
};

const accountCatProps: ComponentProperty = {
  componentId: "acc-cat-view",
  label: "View Account Category",
  column: "Masterdata",
  order: 21,
};

const viewRelManagerProps: ComponentProperty = {
  componentId: "view-relationship-manager",
  label: "View Relationship Manager",
  column: "Masterdata",
  order: 22,
};

const importAccBalProps: ComponentProperty = {
  componentId: "import-account-balance",
  label: "Import Account Balance",
  column: "Main",
  order: 1,
  openDialog: true,
};

//"properties": "[{\"key\":\"label\",\"value\":\"Import ACC_BAL\"},{\"key\":\"column\",\"value\":\"Main\"},{\"key\":\"onclick\",\"value\":\"openDialog\"}]"

export const componentProps = [
  { componentId: "acc-view", properties: JSON.stringify(viewAccProps) },
  { componentId: "templates", properties: JSON.stringify(templatesProps) },
  { componentId: "acc-cat-view", properties: JSON.stringify(accountCatProps) },
  { componentId: "other-views", properties: JSON.stringify(otherViewsProps) },
  {
    componentId: "view-relationship-manager",
    properties: JSON.stringify(viewRelManagerProps),
  },
  {
    componentId: "import-account-balance",
    properties: JSON.stringify(importAccBalProps),
  },
];

export function getComponentProperties200Response2() {
  const resp: PagedModelEntityModelComponentPropertiesDto = {
    _embedded: {
      entities: componentProps,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };

  return resp;
}

export function getComponentProperties200Response3() {
  const resp: PagedModelEntityModelComponentPropertiesDto = {
    _embedded: {
      entities: componentProps,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };

  return resp;
}

const accountCatEntities: EntityModelNostroCategoryDto[] = [
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:25:43",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:49:28",
    statusId: 2,
    status: "del",
    id: 2,
    code: "AAA",
    text: null,
    comment: "Test comment",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:28:59",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:28:59",
    statusId: 2,
    status: "del",
    id: 3,
    code: "A2A",
    text: "sa2",
    comment: "Test comment",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 16:35:52",
    updateUser: "udo.be",
    updateTs: "03.06.2024 16:35:52",
    statusId: 2,
    status: "del",
    id: 5,
    code: "A3A",
    text: "sa2",
    comment: "",
  },
  {
    createUser: "udo.be",
    createTs: "03.06.2024 12:21:04",
    updateUser: "udo.be",
    updateTs: "03.06.2024 15:59:14",
    statusId: 1,
    status: "check",
    id: 21,
    code: "n.a.",
    text: "ciao",
    comment: "bla2",
  },
];

export function getAllNostroCategories200Response2() {
  return {
    _embedded: {
      entities: accountCatEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

export function getGetAllNostroCategories200Response3() {
  return {
    data: accountCatEntities,
    last_page: 10,
  };
}

export function getNostroCategory200Response2() {
  return accountCatEntities[1];
}

const nostroEntities: EntityModelNostroOverviewDto[] = [
  {
    id: 1,
    number: "96500074",
    name: null,
    code: "A3A",
    riskComplianceCode: "na",
    riskFraudCode: "na",
    status: "check",
    upn: "u23840",
    commentCount: 4,
    kstaChfBr: 2,
    kstaCcyBr: 0,
    busaldoChfBr: 2,
    busaldoCcyBr: 0,
    hasLimits: false,
  },
  {
    id: 2,
    number: "96500075",
    name: null,
    code: "A3A",
    riskComplianceCode: "na",
    riskFraudCode: "na",
    status: "check",
    upn: "u23840",
    commentCount: 0,
    kstaChfBr: 1,
    kstaCcyBr: 0,
    busaldoChfBr: 1,
    busaldoCcyBr: 0,
    hasLimits: false,
  },
];

export function getAllNostro200Response() {
  return {
    _embedded: {
      entities: nostroEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const AccountBalanceDtoEntities: EntityModelAccountBalanceDto[] = [
  {
    id: 1,
    number: "2661.001.02",
    currency: "CHF",
    cobDate: "24.07.2024",
    description: "CHF",
    kstaValueChf: "-9394000.06",
    kstaValueChfBreach: true,
    kstaValueCcy: "-9394000.06",
    kstaValueCcyBreach: false,
    busaldoDate: "19.03.2024",
    busaldoValueChf: "9394.06",
    busaldoValueChfBreach: true,
    busaldoValueCcy: "9394.06",
    busaldoValueCcyBreach: false,
    hasLimits: false,
  },
  {
    id: 4,
    number: "2661.001.01",
    currency: "CHF",
    cobDate: "10.06.2024",
    description: "CHF",
    kstaValueChf: "9394.06",
    kstaValueChfBreach: true,
    kstaValueCcy: "9394.06",
    kstaValueCcyBreach: false,
    busaldoDate: "19.03.2024",
    busaldoValueChf: "9394.06",
    busaldoValueChfBreach: true,
    busaldoValueCcy: "9394.06",
    busaldoValueCcyBreach: false,
    hasLimits: false,
  },
];

export function getAllAccountBalance200Response() {
  return {
    _embedded: {
      entities: AccountBalanceDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const nostroDetail: NostroDetailDto = {
  id: 1,
  number: "96500074",
  name: null,
  addrL1: "ANON VRCGDVRANN",
  addrL2: "ANON VRCGDVRANNTRLOKAGKRYREB",
  addrL3: "ANON CDYAHCWRTNHUZIVLUPLSABAG",
  addrL4: "ANON DDBTTULHNQZHLOOFOIUOYPFKP",
  description: "test20203 test",
  nostroCategoryId: 5,
  riskFraudId: 1,
  riskComplianceId: 1,
  rmId: 1,
  largeTurnover: true,
  plRelevant: true,
  watchdog: false,
  depotRelevant: false,
  statusId: 1,
  valChfLimit: 120,
  rmNumber: "AEBT",
  upn: "u23840",
  rmName: "OH SMZBONDC ISJID",
  phone: "YdvDhtAsLghPXAgtbprXPZkhnfLTBSX",
  orgUnit: "TXLC",
  emailSentAt: null,
  emailSentBy: null,
};

export function getNostroDetail200Response() {
  return nostroDetail;
}

const relationshipManagerEntities: EntityModelRelationshipManagerDto[] = [
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    status: "new",
    id: 139,
    number: "BDYX",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    status: "new",
    id: 140,
    number: "BERM",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "ADMIN",
    createTs: "05.06.2024 16:39:16",
    updateUser: "udo.be",
    updateTs: "05.06.2024 16:42:05",
    statusId: 2,
    status: "del",
    id: 1,
    number: "0007",
    upn: "000001",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
  {
    createUser: "udo.be",
    createTs: "11.06.2024 14:49:21",
    updateUser: "udo.be",
    updateTs: "11.06.2024 14:49:21",
    statusId: 3,
    status: "new",
    id: 138,
    number: "4786",
    upn: "",
    name: null,
    phone: null,
    address: null,
    orgUnit: null,
    emailSentAt: null,
    emailSentBy: "null",
  },
];

export function getAllRelationshipManagers200Response2() {
  return {
    _embedded: {
      entities: relationshipManagerEntities,
    },
    page: {
      size: 5,
      totalElements: 4,
      totalPages: 1,
      number: 100,
    },
  };
}

export function getGetLastRelationshipManagersImport200Response2() {
  return {
    _embedded: {
      entities: [
        Array.from(Array(faker.number.int({ min: 1, max: 10 }))).keys(),
      ].map((_) => ({
        createUser: faker.lorem.slug(1),
        createTs: faker.lorem.slug(1),
        updateUser: faker.lorem.slug(1),
        updateTs: faker.lorem.slug(1),
        _links: [Array.from(Array(5)).keys()]
          .map((_) => ({
            [faker.lorem.word()]: {
              href: faker.lorem.slug(1),
              hreflang: faker.lorem.slug(1),
              title: faker.lorem.slug(1),
              type: faker.lorem.slug(1),
              deprecation: faker.lorem.slug(1),
              profile: faker.lorem.slug(1),
              name: faker.person.fullName(),
              templated: faker.datatype.boolean(),
            },
          }))
          .reduce((acc, next) => Object.assign(acc, next), {}),
      })),
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const accountBalanceCobDtoEntities: EntityModelAccountBalanceCobDto[] = [
  {
    id: 2,
    cobDate: "10.06.2024",
    count: 1,
  },
  {
    id: 3,
    cobDate: "05.07.2024",
    count: 1,
  },
  {
    id: 1,
    cobDate: "24.07.2024",
    count: 1,
  },
  {
    id: 4,
    cobDate: "24.07.2024",
    count: 1,
  },
  {
    id: 5,
    cobDate: "24.07.2024",
    count: 1,
  },
];

export function getAllAccountBalanceCob200Response() {
  return {
    _embedded: {
      entities: accountBalanceCobDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const accountBalanceLimitBreachDtoEntities: EntityModelAccountBalanceLimitBreachDto[] =
  [
    {
      id: 1,
      number: "96500074",
      name: null,
      riskFraud: "not applicable",
      status: "check",
      count: 2,
      kstaChf: 2,
      kstaCcy: 0,
      busaldoValueChf: 2,
      busaldoValueCcy: 2,
    },
    {
      id: 2,
      number: "96500075",
      name: null,
      riskFraud: "not applicable",
      status: "check",
      count: 1,
      kstaChf: 1,
      kstaCcy: 0,
      busaldoValueChf: 1,
      busaldoValueCcy: 1,
    },
  ];

export function getAllAccountBalanceLimitBreach200Response() {
  return {
    _embedded: {
      entities: accountBalanceLimitBreachDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const accountBalanceMissingCobDtoEntities: EntityModelAccountBalanceMissingCobDto[] =
  [
    {
      id: 2,
      cobDate: "10.06.2024",
      count: 0,
      weekday: "3",
    },
    {
      id: 32,
      cobDate: "11.06.2024",
      count: 2,
      weekday: "3",
    },
  ];

export function getAllAccountBalanceMissingCob200Response() {
  return {
    _embedded: {
      entities: accountBalanceMissingCobDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const nostroVsAccountBalanceEntities: EntityModelNostroVsAccountBalanceDto[] = [
  {
    id: 1,
    number: "96500074",
    name: null,
    riskFraud: "not applicable",
    status: "check",
    count: 4,
    minCobDate: "10.06.2024",
    maxCobDate: "24.07.2024",
  },
  {
    id: 2,
    number: "96500075",
    name: null,
    riskFraud: "not applicable",
    status: "check",
    count: 1,
    minCobDate: "24.07.2024",
    maxCobDate: "24.07.2024",
  },
];

export function getAllNostroVsAccountBalance200Response() {
  return {
    _embedded: {
      entities: nostroVsAccountBalanceEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}

const otherNostroViewDtoEntities: EntityModelOtherNostroViewDto[] = [
  {
    id: 1,
    title: "Cob Date Missing Account Balance",
    count: 35,
    route: "/other-views/cob-dt-missing",
  },
  {
    id: 2,
    title: "Nostro Vs Account Balance",
    count: 2,
    route: "/other-views/nos-vs-acc-bal",
  },
  {
    id: 3,
    title: "Account Balance Limit Breach",
    count: 2,
    route: "/other-views/acc-bal-lim-br",
  },
  {
    id: 4,
    title: "Cob Date Vs Account Balance",
    count: 5,
    route: "/other-views/cob-dt-vs-acc-bal",
  },
];

export function getOtherNostroViews200Response() {
  return {
    _embedded: {
      entities: otherNostroViewDtoEntities,
    },
    _links: [Array.from(Array(5)).keys()]
      .map((_) => ({
        [faker.lorem.word()]: {
          href: faker.lorem.slug(1),
          hreflang: faker.lorem.slug(1),
          title: faker.lorem.slug(1),
          type: faker.lorem.slug(1),
          deprecation: faker.lorem.slug(1),
          profile: faker.lorem.slug(1),
          name: faker.person.fullName(),
          templated: faker.datatype.boolean(),
        },
      }))
      .reduce((acc, next) => Object.assign(acc, next), {}),
    page: {
      size: faker.number.int({ min: undefined, max: undefined }),
      totalElements: faker.number.int({ min: undefined, max: undefined }),
      totalPages: faker.number.int({ min: undefined, max: undefined }),
      number: faker.number.int({ min: undefined, max: undefined }),
    },
  };
}
