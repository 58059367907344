import { ZfDivider, ZfLoadingSpinner, ZfTextButton } from "@ccx/zafire-react";
import { Paper } from "../../../../components/common/components/Paper";
import { useNavigate } from "react-router-dom";
import {
  AppControllerService,
  EntityModelComponentPropertiesDto,
  PagedModelEntityModelJobLogDto,
} from "../../../../openapi";
import authorizedCall from "../../../../utils/authorizedCallUtils";
import { useCallback, useEffect, useRef, useState } from "react";
import { ComponentProperty } from "../../../../mocks/mock-data";
import { useAppDispatch } from "../../../../store/store";
import ModalConfirmation from "../../../../components/pages/ConfirmModal";
import { JobControllerService } from "../../../../openapi";

export const UserPanel = () => {
  const navigate = useNavigate();
  const [dbContentPanel, setDbContentPanel] = useState<JSX.Element[]>([]);
  const [componentPanelFetching, setComponentPanelFetching] = useState(false);
  const [dialogContent, setDialogContent] = useState({
    title: "",
    body: "",
    confirm: false,
  });

  const dialog = useRef<HTMLDialogElement>();

  function openDialog() {
    setDialogContent({
      title: "Confirmation",
      body: "Confirm import of account balance?",
      confirm: true,
    });
    dialog.current && dialog.current.showModal();
  }

  function closeDialog() {
    console.log("closeDialog..");
    dialog.current && dialog.current.close();
  }

  function confirmDialog() {
    console.log("confirmDialog..");
    dialog.current && dialog.current.close();

    setComponentPanelFetching(true);
    authorizedCall(JobControllerService.restartJob, {
      jobName: "IMPORT_ACC_BAL",
    })
      .then(() => {
        authorizedCall(
          JobControllerService.getLastJobRun,
          { jobName: "IMPORT_ACC_BAL" },
          true
        ).then((resp: PagedModelEntityModelJobLogDto) => {
          const list = resp._embedded?.entities;
          console.info("DEBUG - getLastJobRun:", list && list[0]);
          const textBody =
            "Account balance successfully imported at " + list![0].createTs;
          setDialogContent({ title: "Result", body: textBody, confirm: false });
          dialog.current && dialog.current.showModal();
        });
      })
      .finally(() => {
        setComponentPanelFetching(false);
      });
  }

  const dispatch = useAppDispatch();

  const createUserPanel = useCallback(
    function createUserPanel(entities: EntityModelComponentPropertiesDto[]) {
      let userPanelMap: Map<string, ComponentProperty[]> = new Map();

      entities
        .map((c) => JSON.parse(c.properties))
        .sort((a, b) => {
          return a.order - b.order;
        })
        .forEach((component: ComponentProperty) => {
          let columnName = component.column;
          if (!columnName) throw Error("Found component without column name.");

          let label = component.label;
          if (!label) throw Error("Found component without label text.");

          if (!userPanelMap.has(columnName)) {
            const list: ComponentProperty[] = [component];
            userPanelMap.set(columnName, list);
          } else {
            const list = userPanelMap.get(columnName);
            if (
              list?.find((c) => c.componentId === component.componentId) ===
              undefined
            ) {
              list?.push(component);
            }
          }
        });
      //now map is sorted!
      let resp: any[] = [];

      userPanelMap.forEach((value, key) => {
        resp.push(
          <div className="lgrid-item" key={key}>
            <div
              className="heading"
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                backgroundColor: "var(--color-action-primary-80)",
              }}
            >
              <div
                style={{
                  color: "white",
                }}
                className="heading--five"
              >
                {key}
              </div>
            </div>
            {value.map((component) => {
              const componentRoute = component.componentId;
              return (
                <ZfTextButton
                  style={{
                    width: "90%",
                    alignSelf: "center",
                    margin: "10px 0",
                  }}
                  key={component.label!}
                  hierarchy="secondary"
                  onClick={() => {
                    return component.openDialog
                      ? openDialog()
                      : navigate(`/${componentRoute}`);
                  }}
                >
                  {component.label}
                </ZfTextButton>
              );
            })}
          </div>
        );
      });

      return resp;
    },
    [navigate]
  );

  useEffect(() => {
    //console.log('Component props loading..')
    setComponentPanelFetching(true);

    const getGrants = async () => {
      const promise = authorizedCall(
        AppControllerService.getComponentProperties,
        {}
      );
      return promise
        .then((resp: any) => {
          console.log("Grant response:", resp);
          if (!resp) throw new Error();
          return createUserPanel(resp);
        })
        .catch((error) => {
          console.info(error);
          /*setComponentPanelFetching(false);
        if ((error.status && error.status === 401) || (error.message && error.message.includes('Failed to fetch'))) {
          dispatch(authActions.authError(error.error?.toString()));
        } else {
          dispatch(appStateActions.error(error))
        }*/
        })
        .finally(() => {
          setComponentPanelFetching(false);
        });
    };

    getGrants().then((newContentPanel) => {
      if (!newContentPanel) return;
      setDbContentPanel(newContentPanel);
      setComponentPanelFetching(false);
      console.log("Component props loaded..");
    });
  }, [createUserPanel, dispatch]);

  return (
    <>
      <Paper>
        <>
          <div className="heading">
            <div className="heading--four">User Panel</div>
          </div>
          <ZfDivider
            style={{ height: "1px", margin: "10px 0", display: "block" }}
          ></ZfDivider>

          <ModalConfirmation
            content={dialogContent}
            onClose={closeDialog}
            onConfirm={confirmDialog}
            ref={dialog}
          />

          {componentPanelFetching && (
            <div
              id="spinnerContainer"
              data-testid="spinner"
              style={{
                justifyContent: "center",
                alignItems: "center",
                display: "flex",
              }}
            >
              <ZfLoadingSpinner
                size="large"
                color="primary"
                type="infinite"
              ></ZfLoadingSpinner>
            </div>
          )}
          {!componentPanelFetching && (
            <div className="lgrid">{dbContentPanel}</div>
          )}
        </>
      </Paper>
    </>
  );
};
