import { PropsWithChildren } from "react";
import {
  authorize,
  checkForTokens,
  refresh,
  toLoginPage,
} from "../../store/auth-utils";
import { useAppDispatch, useAppSelector } from "../../store/store";
import { Authentication, authActions } from "../../store/auth-slice";

const Oidc: React.FC<PropsWithChildren> = (props) => {
  const authError = useAppSelector((state) => state.authSlice.authError);
  const authTokens = useAppSelector((state) => state.authSlice.authTokens);

  const dispatch = useAppDispatch();

  const isAuth = checkForTokens();
  //const authTokens: Authentication = readAuthToken();

  /**
   * check
   */
  const location = window.location.href;
  // console.log('isAuth:', isAuth, 'isLoading:', isLoading, 'location:', location);
  console.log("isAuth:", isAuth, "location:", location);
  //alert(location);
  /**
   * If no access token in local storage
   * If it isn't in fetch
   */
  if (!isAuth /*&& !isLoading*/ && location.indexOf("authorize?code=") !== -1) {
    //dispatch(authActions.loading(true));
    authorize()
      .then((resp) => {
        //dispatch(authActions.loading(false));
        console.log("authorize response:", resp.url, resp.status, resp.body);
        if (!resp?.ok) {
          //throw Error('authorize error');
        } else {
          resp?.json().then((content) => {
            //console.log('response.json() :' + JSON.stringify(content));
            const authentication: Authentication = {
              accessToken: content.access_token,
              idToken: content.id_token,
              refreshToken: content.refresh_token,
            };
            dispatch(authActions.signIn(authentication));
            //console.log('redirect to:' + env.REACT_APP_URL!);
            //window.location.href = env.REACT_APP_URL!
          });
        }
      })
      .catch((error) => {
        if (error.message === "authorize error") {
          toLoginPage();
        } else {
          console.error("catched error:", error);
          throw error;
        }
      })
      .finally(() => {
        //dispatch(authActions.loading(false));
      });

    /**
     * try to use refresh token to obtain new access token
     */
  } else if (isAuth /*&& !isLoading*/ && authError === 401) {
    if (!authTokens?.refreshToken) {
      dispatch(authActions.signOut());
    } else {
      refresh(authTokens?.refreshToken)
        .then((resp) => {
          //alert('refresh resp:\n ' + resp.status + '\n' + resp.body);
          if (resp.status === 400) {
            //dispatch(authActions.signOut());
            //return;
          }
          resp?.json().then((content) => {
            //console.log('response.json() :' + JSON.stringify(content));
            const authentication: Authentication = {
              accessToken: content.access_token,
              idToken: content.id_token,
            };
            dispatch(authActions.signIn(authentication));
            //dispatch(authActions.loading(false));
            dispatch(authActions.authError(-1));
          });
        })
        .finally(() => {
          //dispatch(authActions.loading(false));
        });

      //dispatch(authActions.loading(true));
    }
  } else if (!isAuth) {
    //alert('toLoginPage');
    toLoginPage();
  }

  console.log("RENDER - Oidc.tsx");
  return <>{props.children}</>;
};

export default Oidc;
