import { PropsWithChildren } from "react";
import "@ccx/foundations/variables.css";
import "@ccx/foundations/font-faces.css";
import { defineCustomElements, ZfAppContainer } from "@ccx/zafire-react";
import { StrictMode } from "react";

const ZfConfig: React.FC<PropsWithChildren> = (props) => {
  defineCustomElements();

  return (
    <StrictMode>
      <ZfAppContainer>{props.children}</ZfAppContainer>
    </StrictMode>
  );
};

export default ZfConfig;
